<template>
	<Config>
		<template v-slot:page>
			<v-data-table
				:headers="headers"
				:items="accessMaps"
				:search="search"
				:loading="loading"
				hide-default-footer
				item-key="_id"
				must-sort
				no-data-text="Não há resultados"
				no-results-text="Não há resultados"
			>
				<template v-slot:top>
					<v-toolbar>
						<v-toolbar-title>Acessos</v-toolbar-title>
						<v-spacer />
						<v-text-field
							outlined
							dense
							v-model="search"
							append-icon="mdi-magnify"
							label="Procurar"
							style="margin-right: 10px; max-width: 300px"
							hide-details
						/>
					</v-toolbar>
				</template>
				<template v-slot:item.roles="{ item }">
					<v-chip v-for="role in item.roles" :key="role" color="dark-grey" style="margin: 5px">
						{{ config.roles.find(r => r.key === role).name }}
					</v-chip>
				</template>
				<template v-slot:item.users="{ item }">
					<v-chip v-for="user in item.users" :key="user._id" color="dark-grey" style="margin: 5px">
						{{ user.name }}
					</v-chip>
				</template>
				<template v-slot:item.edit="{ item }">
					<v-btn v-if="features.listChanges" icon @click="openAccessChangeLog(item)">
						<v-icon>mdi-chart-timeline</v-icon>
					</v-btn>
					<v-btn icon @click="openAccessForm(item)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<v-dialog v-model="showAccessForm">
				<AccessForm :accessMap="selectedAccess" :onClose="closeAccessForm" />
			</v-dialog>
			<v-dialog v-if="selectedAccess" v-model="showAccessChangeLog" width="750px">
				<ChangeLog model="accessMap" :id="selectedAccess._id" />
			</v-dialog>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import Config from "../views/Config.vue";
import AccessForm from "../components/AccessForm";
import ChangeLog from "../components/ChangeLog.vue";

import { getAccessMaps } from "../api/accessMaps";

export default Vue.extend({
	name: "Access",
	components: { Config, AccessForm, ChangeLog },
	created() {
		if (!this.features.listConfigsAccess) this.$router.push("/");

		this.handleGetAccessMaps();
	},
	computed: mapState(["features", "config"]),
	data() {
		return {
			loading: false,

			accessMaps: [],

			headers: [
				{ text: "Nome", value: "name", align: "center", width: 100 },
				{ text: "Cargos", value: "roles", align: "center", sortable: false },
				{ text: "Utilizadores", value: "users", align: "center", sortable: false },
				{ text: "Ações", value: "edit", align: "center", sortable: false, width: 110 },
			],
			search: "",

			showAccessForm: false,
			selectedAccess: null,

			showAccessChangeLog: false,
		};
	},
	methods: {
		async handleGetAccessMaps() {
			const response = await getAccessMaps();

			if (response.status === 200) {
				this.accessMaps = response.data;
			}
		},
		openAccessForm(item) {
			if (item) {
				this.selectedAccess = this.accessMaps.find(s => s._id === item._id);
			} else {
				this.selectedAccess = null;
			}

			this.showAccessForm = true;
		},
		closeAccessForm() {
			window.location.reload();
		},
		openAccessChangeLog(item) {
			this.selectedAccess = this.accessMaps.find(i => i._id === item._id);

			this.showAccessChangeLog = true;
		},
	},
});
</script>
