import { api } from "../utils/request";

async function getEmailMaps() {
	const res = await api({
		method: "get",
		url: "/api/emails",
	});

	return res;
}

async function editEmailMap(email) {
	const res = await api({
		method: "put",
		url: `/api/emails/${email._id}`,
		data: email,
	});

	return res;
}

export { getEmailMaps, editEmailMap };
