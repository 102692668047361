<template>
	<v-container style="display: flex; flex-direction: column; gap: 10px">
		<center>
			<v-btn-toggle>
				<v-btn v-if="features.listConfigsAccess" to="/config/access">Acessos</v-btn>
				<v-btn v-if="features.listConfigsEmails" to="/config/emails">Emails</v-btn>
				<v-btn v-if="features.listConfigsLists" to="/config/lists">Listas</v-btn>
				<v-btn v-if="features.listConfigsNotifications" to="/config/notifications">Notificações</v-btn>
				<v-btn v-if="user.role === 'sysAdmin'" to="/config/errors">Erros</v-btn>
			</v-btn-toggle>
		</center>
		<slot name="page" />
	</v-container>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
	name: "Config",
	created() {
		if (this.$route.name === "Config") {
			if (this.features.listConfigsAccess) this.$router.push("/config/access");
			else if (this.features.listConfigsEmails) this.$router.push("/config/emails");
			else if (this.features.listConfigsLists) this.$router.push("/config/lists");
			else if (this.features.listConfigsNotifications) this.$router.push("/config/notifications");
		}
	},
	computed: mapState(["user", "features"]),
});
</script>
