<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<div style="display: flex; gap: 10px">
						<v-text-field outlined v-model="name" label="Nome" />
						<v-checkbox v-model="active" hint="Ativo" persistent-hint style="top: -10px; position: relative" />
					</div>
					<v-text-field outlined v-model="cronReadable" label="Frequência" disabled />
					<v-text-field outlined v-model="from" label="De" />
					<div v-if="splitOffices" style="padding-bottom: 20px">
						<div
							v-for="office in offices"
							:key="office.key"
							style="border: 1px solid Gray; border-radius: 5px; padding: 10px; margin-bottom: 10px"
						>
							<span>{{ office.name }}</span>
							<v-combobox
								v-if="splitOffices[office.key]"
								outlined
								v-model="splitOffices[office.key].emails"
								:items="users.map(u => u.email)"
								label="Emails"
								multiple
								chips
								deletable-chips
							/>
							<v-combobox
								outlined
								v-model="splitOffices[office.key].cc"
								:items="users.map(u => u.email)"
								label="CC"
								multiple
								chips
								deletable-chips
							/>
							<v-combobox
								outlined
								v-model="splitOffices[office.key].bcc"
								:items="users.map(u => u.email)"
								label="BCC"
								multiple
								chips
								deletable-chips
							/>
							<v-combobox
								outlined
								v-model="splitOffices[office.key].replyTo"
								:items="users.map(u => u.email)"
								label="Responde a"
								multiple
								chips
								deletable-chips
								hide-details
							/>
						</div>
					</div>
					<div v-else>
						<v-combobox
							outlined
							v-model="emails"
							:items="users.map(u => u.email)"
							label="Emails"
							multiple
							chips
							deletable-chips
						/>
						<v-combobox
							outlined
							v-model="cc"
							:items="users.map(u => u.email)"
							label="CC"
							multiple
							chips
							deletable-chips
						/>
						<v-combobox
							outlined
							v-model="bcc"
							:items="users.map(u => u.email)"
							label="BCC"
							multiple
							chips
							deletable-chips
						/>
						<v-combobox
							outlined
							v-model="replyTo"
							:items="users.map(u => u.email)"
							label="Responde a"
							multiple
							chips
							deletable-chips
						/>
					</div>
					<v-text-field outlined v-model="subject" label="Assunto" />
					<v-textarea outlined v-model="html" label="HTML" />
					<v-btn :disabled="loading" type="submit" color="primary">
						{{ email ? "Atualizar" : "Adicionar" }}
					</v-btn>
					<br /><br />
				</v-col>
			</v-row>
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { editEmailMap } from "../api/emailMaps";

export default Vue.extend({
	name: "EmailForm",
	props: ["email", "onClose"],
	async created() {
		await this.handleGetUsers();

		if (this.email) {
			this.setEmail(this.email);
		}
	},
	watch: {
		email: function (newVal) {
			if (newVal) {
				this.setEmail(this.email);
			} else {
				this.name = "";
				this.cronReadable = "";
				this.emails = [];
			}
		},
	},
	computed: mapState(["offices"]),
	data() {
		return {
			loading: false,

			users: [],

			name: "",
			cronReadable: "",
			emails: [],
			splitOffices: null,
			from: "",
			subject: "",
			html: "",
			cc: [],
			bcc: [],
			replyTo: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setEmail(newVal) {
			this.splitOffices = null;

			this.active = newVal.active;
			this.name = newVal.name;
			this.cronReadable = newVal.cronReadable;
			this.from = newVal.from;
			this.subject = newVal.subject;
			this.html = newVal.html;
			this.emails = newVal.emails;
			this.cc = newVal.cc;
			this.bcc = newVal.bcc;
			this.replyTo = newVal.replyTo;
			if (newVal.splitOffices) {
				this.splitOffices = newVal.splitOffices;

				for (const office of this.offices) {
					if (!this.splitOffices[office.key]) this.splitOffices[office.key] = { emails: [] };
				}
			}
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		async handleSubmit() {
			const { active, name, emails, splitOffices, from, cc, bcc, replyTo, subject, html } = this;

			this.loading = true;

			const response = await editEmailMap({
				_id: this.email._id,
				active,
				name,
				emails,
				splitOffices,
				from,
				cc,
				bcc,
				replyTo,
				subject,
				html,
			});

			if (response.status === 200) {
				this.onClose();

				this.openToast({ message: "Email atualizado com sucesso", color: "green" });
			}

			this.loading = false;
		},
	},
});
</script>
