<template>
	<Config>
		<template v-slot:page>
			<v-data-table
				:headers="headers"
				:items="errors"
				:search="search"
				:loading="loading"
				:items-per-page="errors.length"
				hide-default-footer
				item-key="_id"
				must-sort
				no-data-text="Não há resultados"
				no-results-text="Não há resultados"
			>
				<template v-slot:top>
					<v-toolbar>
						<v-toolbar-title>Erros</v-toolbar-title>
						<v-spacer />
						<v-text-field
							outlined
							dense
							v-model="search"
							append-icon="mdi-magnify"
							label="Procurar"
							style="margin-right: 10px; max-width: 300px"
							hide-details
						/>
					</v-toolbar>
				</template>
				<template v-slot:item._modified="{ item }">
					{{ formatDate(item._modified, "DD-MM-YYYY HH:mm") }}
				</template>
				<template v-slot:item.stack="{ item }">
					{{ item.data ? item.data.stack : "" }}
				</template>
				<template v-slot:item.method="{ item }">
					{{ item.data && item.type === "backend" ? `${item.data.method} ${item.data.endpoint}` : "" }}
				</template>
				<template v-slot:item.info="{ item }">
					<div v-if="item.data">
						<div v-if="item.type === 'frontend'">{{ item.data.info }}</div>
						<div v-else>
							<div>{{ JSON.stringify(item.data.headers) }}</div>
							<div>{{ JSON.stringify(item.data.query) }}</div>
							<div>{{ JSON.stringify(item.data.body) }}</div>
						</div>
					</div>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="() => handleDeleteError(item)">
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";

import Config from "../views/Config.vue";

import { getErrors, deleteError } from "../api/errors";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "Errors",
	components: { Config },
	created() {
		this.handleGetErrors();
	},
	data() {
		return {
			loading: false,

			errors: [],

			headers: [
				{ text: "App", value: "app", width: 100 },
				{ text: "Type", value: "type", width: 100 },
				{ text: "Count", value: "count", width: 100 },
				{ text: "Last Error", value: "_modified", width: 150 },
				{ text: "Stack", value: "stack", width: 100 },
				{ text: "Ações", value: "actions", align: "right", width: 180 },
				{ text: "Method", value: "method", width: 100 },
				{ text: "Info", value: "info", width: 100 },
			],
			search: "",
		};
	},
	methods: {
		formatDate,
		async handleGetErrors() {
			this.loading = true;

			const response = await getErrors();

			if (response.status === 200) {
				this.errors = response.data;
			}

			this.loading = false;
		},
		async handleDeleteError(item) {
			this.loading = true;

			const response = await deleteError(item._id);

			if (response.status === 200) {
				this.errors = response.data;

				this.handleGetErrors();
			}

			this.loading = false;
		},
	},
});
</script>
