<template>
	<Config>
		<template v-slot:page>
			<v-data-table
				:headers="headers"
				:items="lists"
				:search="search"
				:loading="loading"
				:items-per-page="lists.length"
				hide-default-footer
				item-key="_id"
				must-sort
				no-data-text="Não há resultados"
				no-results-text="Não há resultados"
			>
				<template v-slot:top>
					<v-toolbar>
						<v-toolbar-title>Lists</v-toolbar-title>
						<v-spacer />
						<v-text-field
							outlined
							dense
							v-model="search"
							append-icon="mdi-magnify"
							label="Procurar"
							style="margin-right: 10px; max-width: 300px"
							hide-details
						/>
					</v-toolbar>
				</template>
				<template v-slot:item.name="{ item }">
					{{ translations[item.name] ?? item.name }}
				</template>
				<template v-slot:item.items="{ item }">
					<v-chip v-for="listItem in item.items" :key="listItem.key" color="dark-grey" style="margin: 5px">
						{{ listItem.name }}
					</v-chip>
				</template>
				<template v-slot:item.edit="{ item }">
					<v-btn icon @click="openForm(item)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<v-dialog v-model="showForm">
				<ListForm :translations="translations" :list="selectedList" :onClose="closeForm" />
			</v-dialog>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import Config from "../views/Config.vue";
import ListForm from "../components/ListForm";

import { getConfig } from "../api/auth";

export default Vue.extend({
	name: "Lists",
	components: { Config, ListForm },
	created() {
		if (!this.features.listConfigsLists) this.$router.push("/");

		this.handleGetConfig();
	},
	computed: mapState(["features"]),
	data() {
		return {
			loading: false,

			translations: {},
			lists: [],

			headers: [
				{ text: "Tipo", value: "name", align: "center", width: 100 },
				{ text: "Items", value: "items", align: "center", sortable: false },
				{ text: "Editar", value: "edit", align: "center", sortable: false },
			],
			search: "",

			showForm: false,
			selectedList: null,
		};
	},
	methods: {
		async handleGetConfig() {
			this.loading = true;

			const response = await getConfig();

			this.lists = [];
			if (response.status === 200 && response.data.config) {
				if (response.data.config.translations) {
					this.translations = response.data.config.translations.reduce(
						(obj, cur) => ({ ...obj, [cur.key]: cur.name }),
						{},
					);
				}

				for (const [name, items] of Object.entries(response.data.config)) {
					if (!["apiKeys", "translations"].includes(name) && Array.isArray(items)) {
						this.lists.push({ name, items });
					}
				}
			}

			this.loading = false;
		},
		openForm(item) {
			if (item) {
				this.selectedList = item;
			} else {
				this.selectedList = null;
			}

			this.showForm = true;
		},
		closeForm() {
			window.location.reload();
		},
	},
});
</script>
