<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<v-row>
						<v-col>
							<v-menu
								v-model="showDateToSendPicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="dateToSend"
										label="Data de Envio"
										v-bind="attrs"
										v-on="on"
										readonly
										:rules="[required]"
										hint="Obrigatório"
										persistent-hint
									/>
								</template>
								<v-date-picker v-model="dateToSend" color="primary" @input="showDateToSendPicker = false" />
							</v-menu>
						</v-col>
						<v-col>
							<v-menu
								v-model="showTimeToSendPicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="timeToSend"
										label="Hora de Envio"
										v-bind="attrs"
										v-on="on"
										readonly
										:rules="[required]"
										hint="Obrigatório"
										persistent-hint
									/>
								</template>
								<v-time-picker
									v-model="timeToSend"
									color="primary"
									format="24hr"
									@input="showTimeToSendPicker = false"
								/>
							</v-menu>
						</v-col>
					</v-row>
					<v-select
						outlined
						v-model="channels"
						item-text="name"
						item-value="key"
						label="Canais"
						:items="config.channels"
						:rules="[required]"
						multiple
						chips
					/>
					<v-text-field outlined v-model="title" label="Título" :rules="[required]" />
					<v-textarea outlined v-model="description" label="Descrição" />
					<v-combobox
						outlined
						v-model="selectedUsers"
						:items="users"
						item-text="name"
						item-value="_id"
						label="Utilizadores"
						multiple
						chips
						deletable-chips
					/>
					<v-btn :disabled="loading" type="submit" color="primary">
						{{ notification ? "Atualizar" : "Adicionar" }}
					</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import { getUsers } from "../api/users";
import { addNotification, editNotification } from "../api/notifications";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "NotificationForm",
	props: ["notification", "onClose"],
	async created() {
		await this.handleGetUsers();

		if (this.notification) this.setNotification(this.notification);
	},
	watch: {
		notification: function (newVal) {
			if (newVal) {
				this.setNotification(this.notification);
			} else {
				this.dateToSend = dayjs().format("YYYY-MM-DD");
				this.timeToSend = dayjs().format("HH:mm");
				this.channels = [];
				this.type = "";
				this.title = "";
				this.description = "";
				this.selectedUsers = [];
			}
		},
	},
	computed: mapState(["config"]),
	data() {
		return {
			required: value => !!value || "Obrigatório",

			loading: false,

			showDateToSendPicker: false,
			showTimeToSendPicker: false,

			users: [],

			dateToSend: dayjs().format("YYYY-MM-DD"),
			timeToSend: dayjs().format("HH:mm"),
			channels: [],
			type: "custom",
			title: "",
			description: "",
			selectedUsers: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setNotification(newVal) {
			this.dateToSend = formatDate(newVal.dateToSend, "YYYY-MM-DD");
			this.timeToSend = formatDate(newVal.dateToSend, "HH:mm");
			this.channels = newVal.channels;
			this.type = newVal.type;
			this.title = newVal.title;
			this.description = newVal.description;
			this.selectedUsers = newVal.users.map(u => this.users.find(u2 => u.user === u2._id));
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleSubmit() {
			const { notification, dateToSend, channels, timeToSend, type, title, description, selectedUsers } = this;

			if (!this.isFormValid()) return;

			this.loading = true;

			let response = null;
			if (notification) {
				response = await editNotification({
					_id: this.notification._id,
					dateToSend: dayjs(`${dateToSend} ${timeToSend}`),
					channels,
					type,
					title,
					description,
					users: selectedUsers.map(u => ({ user: u._id })),
				});
			} else {
				response = await addNotification({
					dateToSend: dayjs(`${dateToSend} ${timeToSend}`),
					channels,
					type,
					title,
					description,
					users: selectedUsers.map(u => ({ user: u._id })),
				});
			}

			if ([200, 201].includes(response.status)) {
				this.onClose();

				this.openToast({ message: "Notificação adicionada com sucesso", color: "green" });
			}

			this.loading = false;
		},
	},
});
</script>
