import { api } from "../utils/request";

async function getAccessMaps() {
	const res = await api({
		method: "get",
		url: "/api/access",
	});

	return res;
}

async function editAccessMap(accessMap) {
	const res = await api({
		method: "put",
		url: `/api/access/${accessMap._id}`,
		data: accessMap,
	});

	return res;
}

export { getAccessMaps, editAccessMap };
