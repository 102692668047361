<template>
	<Config>
		<template v-slot:page>
			<v-data-table
				:headers="headers"
				:items="emails"
				:search="search"
				:loading="loading"
				:items-per-page="emails.length"
				hide-default-footer
				item-key="_id"
				must-sort
				no-data-text="Não há resultados"
				no-results-text="Não há resultados"
			>
				<template v-slot:top>
					<v-toolbar>
						<v-toolbar-title>Emails</v-toolbar-title>
						<v-spacer />
						<v-text-field
							outlined
							dense
							v-model="search"
							append-icon="mdi-magnify"
							label="Procurar"
							style="margin-right: 10px; max-width: 300px"
							hide-details
						/>
					</v-toolbar>
				</template>
				<template v-slot:item.emails="{ item }">
					<span v-if="item.splitOffices">Emails separados por agência. Editar para ver</span>
					<v-chip v-else v-for="email in item.emails" :key="email" color="dark-grey" style="margin: 5px">
						{{ email }}
					</v-chip>
				</template>
				<template v-slot:item.edit="{ item }">
					<v-btn v-if="features.listChanges" icon @click="openEmailChangeLog(item)">
						<v-icon>mdi-chart-timeline</v-icon>
					</v-btn>
					<v-btn icon @click="openEmailForm(item)">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
			</v-data-table>
			<v-dialog v-model="showEmailForm">
				<EmailForm :email="selectedEmail" :onClose="closeEmailForm" />
			</v-dialog>
			<v-dialog v-if="selectedEmail" v-model="showEmailChangeLog" width="750px">
				<ChangeLog model="emailMap" :id="selectedEmail._id" />
			</v-dialog>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import Config from "../views/Config.vue";
import EmailForm from "../components/EmailForm";
import ChangeLog from "../components/ChangeLog.vue";

import { getEmailMaps } from "../api/emailMaps";

export default Vue.extend({
	name: "Emails",
	components: { Config, EmailForm, ChangeLog },
	created() {
		if (!this.features.listConfigsEmails) this.$router.push("/");

		this.handleGetEmails();
	},
	computed: mapState(["features"]),
	data() {
		return {
			loading: false,

			emails: [],

			headers: [
				{ text: "Nome", value: "name", align: "center", width: 100 },
				{ text: "Frequência", value: "cronReadable", align: "center", width: 150, sortable: false },
				{ text: "Emails", value: "emails", align: "center", sortable: false },
				{ text: "Observações", value: "observations", align: "center", sortable: false },
				{ text: "Ações", value: "edit", align: "center", sortable: false, width: 110 },
			],
			search: "",

			showEmailForm: false,
			selectedEmail: null,

			showEmailChangeLog: false,
		};
	},
	methods: {
		async handleGetEmails() {
			const response = await getEmailMaps();

			if (response.status === 200) {
				this.emails = response.data;
			}
		},
		openEmailForm(item) {
			if (item) {
				this.selectedEmail = this.emails.find(s => s._id === item._id);
			} else {
				this.selectedEmail = null;
			}

			this.showEmailForm = true;
		},
		closeEmailForm() {
			this.handleGetEmails();

			this.showEmailForm = false;
		},
		openEmailChangeLog(item) {
			this.selectedEmail = this.emails.find(i => i._id === item._id);

			this.showEmailChangeLog = true;
		},
	},
});
</script>
