<template>
	<Config>
		<template v-slot:page>
			<v-data-table
				:headers="headers"
				:items="notifications"
				:items-per-page="perPage"
				:sort-by="sortBy"
				:sort-desc="sortDesc"
				:server-items-length="total"
				item-key="_id"
				:search="search"
				:loading="loading"
				must-sort
				no-data-text="Não há resultados"
				no-results-text="Não há resultados"
				:footer-props="{
					itemsPerPageOptions: [10, 20, 50],
					itemsPerPageText: 'Linhas',
					showCurrentPage: true,
					showFirstLastPage: true,
				}"
				@update:options="setOptions"
			>
				<template v-slot:top>
					<v-toolbar>
						<v-toolbar-title>Notificações</v-toolbar-title>
						<v-spacer />
						<v-text-field
							outlined
							dense
							v-model="search"
							append-icon="mdi-magnify"
							label="Procurar"
							style="margin-right: 10px; max-width: 300px"
							hide-details
						/>
						<v-btn
							v-if="features.addNotification"
							fab
							small
							color="primary"
							style="margin-left: 10px"
							@click="openNotificationForm(null)"
						>
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</v-toolbar>
				</template>
				<template v-slot:item.dateToSend="{ item }">
					{{ formatDate(item.dateToSend, "DD-MMM-YYYY HH:mm") }}
				</template>
				<template v-slot:item.channels="{ item }">
					{{ item.channels.map(c => config.channels.find(c2 => c2.key === c)?.name).join(", ") }}
				</template>
				<template v-slot:item.type="{ item }">
					{{ config.notificationTypes.find(t => t.key === item.type)?.name }}
				</template>
				<template v-slot:item.subType="{ item }">
					{{ config.notificationSubTypes.find(t => t.key === item.subType)?.name }}
				</template>
				<template v-slot:item._created="{ item }">
					{{ formatDate(item._created, "DD-MMM-YYYY HH:mm") }}
				</template>
				<template v-slot:item.actions="{ item }">
					<span v-if="diff(Date.now(), item.dateToSend) < 0">
						<v-btn icon @click="openNotificationForm(item)">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
						<v-btn icon @click="handleDeleteNotification(item)">
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</span>
				</template>
			</v-data-table>
			<v-dialog v-model="showNotificationForm">
				<NotificationForm :notification="selectedNotification" :onClose="closeNotificationForm" />
			</v-dialog>
		</template>
	</Config>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import Config from "../views/Config.vue";
import NotificationForm from "../components/NotificationForm";

import { getNotifications, deleteNotification } from "../api/notifications";

import { formatDate, diff } from "../utils/utils";

export default Vue.extend({
	name: "Notifications",
	components: { Config, NotificationForm },
	created() {
		if (!this.features.listConfigsNotifications) this.$router.push("/");
	},
	computed: mapState(["features", "config"]),
	data() {
		return {
			loading: false,

			notifications: [],
			total: 0,

			headers: [
				{ text: "Data de Envio", value: "dateToSend", align: "center", width: 150 },
				{ text: "Canais", value: "channels", align: "center", width: 100 },
				{ text: "Tipo", value: "type", align: "center", width: 100 },
				{ text: "Subtipo", value: "subType", align: "center", width: 100 },
				{ text: "Título", value: "title", align: "center", width: 150 },
				{ text: "Descrição", value: "description", align: "center", width: 150 },
				{ text: "Criado", value: "_created", align: "center", width: 150 },
				{ text: "Ações", value: "actions", align: "center", sortable: false, width: 110 },
			],
			page: 0,
			perPage: 10,
			sortBy: "_created",
			sortDesc: true,
			search: "",

			showNotificationForm: false,
			selectedNotification: null,
		};
	},
	methods: {
		formatDate,
		diff,
		setOptions(options) {
			if (
				this.perPage !== options.itemsPerPage ||
				this.sortBy !== options.sortBy ||
				this.sortDesc !== options.sortDesc
			) {
				this.page = 0;
			}

			this.page = options.page - 1;
			this.perPage = options.itemsPerPage;
			this.sortBy = options.sortBy[0];
			this.sortDesc = options.sortDesc[0];

			this.handleGetNotifications();
		},
		async handleGetNotifications() {
			const response = await getNotifications(this.page, this.perPage, this.sortBy, this.sortDesc, {
				active: true,
				search: this.search,
			});

			if (response.status === 200) {
				this.notifications = response.data.notifications;
				this.total = response.data.total;
			}
		},
		openNotificationForm(item) {
			if (item) {
				this.selectedNotification = this.notifications.find(s => s._id === item._id);
			} else {
				this.selectedNotification = null;
			}

			this.showNotificationForm = true;
		},
		closeNotificationForm() {
			this.handleGetNotifications();

			this.showNotificationForm = false;
		},
		async handleDeleteNotification(item) {
			this.loading = true;

			const response = await deleteNotification(item._id);

			if (response.status === 200) {
				this.handleGetNotifications();
			}

			this.loading = false;
		},
	},
});
</script>
