<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleSubmit">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; max-width: 400px">
					<v-text-field outlined v-model="name" label="Nome" disabled />
					<v-combobox
						outlined
						v-model="selectedRoles"
						:items="config.roles"
						item-text="name"
						item-value="key"
						label="Cargos"
						multiple
						chips
						deletable-chips
					/>
					<v-combobox
						outlined
						v-model="selectedUsers"
						:items="users"
						item-text="name"
						item-value="_id"
						label="Utilizadores"
						multiple
						chips
						deletable-chips
					/>
					<template v-if="features && features.length > 0">
						<v-divider style="margin: 10px 0" />
						<span> Funcionalidades </span>
						<v-expansion-panels flat style="margin: 10px 0 20px 0">
							<v-expansion-panel v-for="feature in features" :key="feature._id" style="border: 1px solid #545454">
								<v-expansion-panel-header>
									<v-badge
										dot
										inline
										:value="feature.roles.length > 0 || feature.users.length > 0"
										style="justify-content: flex-start"
									>
										{{ feature.name }}
									</v-badge>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<v-combobox
										outlined
										v-model="feature.roles"
										:items="config.roles"
										item-text="name"
										item-value="key"
										label="Cargos"
										multiple
										chips
										deletable-chips
										hide-details
									/>
									<br />
									<v-combobox
										outlined
										v-model="feature.users"
										:items="users"
										item-text="name"
										item-value="_id"
										label="Utilizadores"
										multiple
										chips
										deletable-chips
										hide-details
									/>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</template>
					<v-btn :disabled="loading" type="submit" color="primary">
						{{ accessMap ? "Atualizar" : "Adicionar" }}
					</v-btn>
					<br /><br />
				</v-col>
			</v-row>
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { editAccessMap } from "../api/accessMaps";

export default Vue.extend({
	name: "AccessForm",
	props: ["accessMap", "onClose"],
	async created() {
		await this.handleGetUsers();

		if (this.accessMap) {
			this.setAccessMap(this.accessMap);
		}
	},
	watch: {
		accessMap: function (newVal) {
			if (newVal) {
				this.setAccessMap(this.accessMap);
			} else {
				this.name = "";
				this.cronReadable = "";
				this.selectedRoles = [];
				this.selectedUsers = [];
				this.features = [];
			}
		},
	},
	computed: mapState(["config"]),
	data() {
		return {
			loading: false,

			users: [],

			name: "",
			selectedRoles: [],
			selectedUsers: [],
			features: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setAccessMap(newVal) {
			this.name = newVal.name;
			this.cronReadable = newVal.cronReadable;
			this.selectedRoles = newVal.roles.map(r => this.config.roles.find(a => a.key === r));
			this.selectedUsers = newVal.users;
			this.features = newVal.features.map(f => ({
				...f,
				roles: f.roles.map(r => this.config.roles.find(a => a.key === r)),
			}));
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.users = response.data.users;
			}
		},
		async handleSubmit() {
			const { selectedRoles, selectedUsers, features } = this;

			this.loading = true;

			const response = await editAccessMap({
				_id: this.accessMap._id,
				roles: selectedRoles.map(r => r.key),
				users: selectedUsers,
				features: features.map(f => ({ ...f, roles: f.roles.map(r => r.key) })),
			});

			if (response.status === 200) {
				this.onClose();

				this.openToast({ message: "Acesso atualizado com sucesso", color: "green" });
			}

			this.loading = false;
		},
	},
});
</script>
